<template>
  <div class="Brand clearfix">
    <div class="title">
      <h3>品牌优势</h3>
    </div>
    <div class="content">
      <div class="left">
        <div class="txt-top">
          <h3>科学的教育方法</h3>
          <p>专业、专注、创新、激发兴趣、</p>
          <p>丰富想象力、开拓创造力。</p>
        </div>
        <div class="txt-footer">
          <h3>专业的师资力量</h3>
          <p>完备的教研体系，独立开发的教</p>
          <p>育教学系统，完善的教学流程。</p>
        </div>
      </div>
      <div class="centent-img">
        <img src="../../../assets/img/ppys1.png" alt="" />
      </div>
      <div class="right">
        <div class="txt-top">
          <h3>N对1的个性化服务</h3>
          <p>艺术培训课程顾问服务，优师资</p>
          <p>匹配，学管师服务，多对一教学</p>
          <p>服务。</p>
        </div>
        <div class="txt-footer">
          <h3>优秀学员重点培养</h3>
          <p>优异者可获得更大的成长空间和</p>
          <p>舞台，提供艺术游学、电视录制</p>
          <p>等机会</p>
        </div>
      </div>
    </div>
    <div class="certificate clearfix">
      <div class="certificate-txt">
        <p>资深的导师团队 权威的证书认证</p>
      </div>
      <div class="img-show">
        <img :src="brandImage(0)" alt=""  v-lazy-img="brandImage(0)"/>
      </div>
    </div>
    <div class="btsn">
      <div class="btn">全国统一教学大纲</div>
      <div class="btn btn2">国际教学体系认证</div>
      <div class="btn btn3">教研全程课程督导</div>
    </div>
    <div class="title">舞台实践，星光璀璨</div>
    <div class="info">
        为每个孩子规划阶梯式成长体系，从艺术教学到艺术实践（考级、比赛、活动），让孩子在天籁少儿享受艺术、享受精彩童年，实现能力和个性的全方位蜕变。
    </div>
    <div class="over">
      <img src="../../../assets/img/bandOver.png" alt="">
      <img src="../../../assets/img/bandOver1.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      brandArr:[
      
        require("../../../assets/img/brandBg.png"),
      ]
    }
  },
  computed: {
    brandImage() {
      return (index) => this.brandArr[index];
    },
  },
};
</script>

<style lang="less" scoped>
.Brand {
    padding-bottom: 40px;
    .title {
    text-align: center;
    margin: 70px auto;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }
  .content {
    width: 1002px;
    margin: 0 auto;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left,
    .right {
      width: 257px;
      height: 360px;
      display: flex;
      flex-direction: column;
    align-items: center;
    justify-content: space-between;
      .txt-top,.txt-footer{
        display: flex;
        flex-direction: column;
      }
      h3 {
        font-size: 20px;
        font-weight: bold;
        color: #831925;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .certificate {
    width: 1000px;
    height: auto;
   
    margin: 80px auto;
    .certificate-txt {
      text-align: center;

      font-size: 22px;
      font-weight: 500;
      color: #333333;
    }
    .img-show {
      margin-top: 40px;
    }
  }
  .btsn {
    display: flex;
    width: 800px;
    height: auto;
   
    margin: 0 auto;
    justify-content: space-between;
    .btn {
      width: 227px;
      height: 55px;
      background: #dbbc7d;
      border-radius: 28px;
      display: flex;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .btn2{
        background: #E57069;
    }
    .btn3{
        background: #AEC9EF;
    }
  }
  .info{
    margin: 0 auto;
    width: 1017px;
height: 56px;
font-size: 18px;
font-family: SourceHanSansCN-Medium, SourceHanSansCN;
font-weight: 500;
color: #333333;
line-height: 28px;
  }
  .over{
    width: 1000px;
    margin: 50px auto;
    display: flex;
    justify-content: space-evenly;
  }
}
</style>