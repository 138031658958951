<template>
  <div class="personalization clearfix">
    <div class="title">
      <h3>阶段性培养 个性化施教</h3>
    </div>
    <div class="personalization clearfix">
      <div class="left-content">
        <div class="btns">
          <div
            @click="showPersonalization(0)"
            :class="{ active: currentPersonalizationIndex === 0 }"
          >
            兴趣启蒙
          </div>
          <div
            @click="showPersonalization(1)"
            :class="{ active: currentPersonalizationIndex === 1 }"
          >
            系统进阶
          </div>
          <div
            @click="showPersonalization(2)"
            :class="{ active: currentPersonalizationIndex === 2 }"
          >
            专业深造
          </div>
        </div>
        <div class="top-txt">
          <h3>{{ currentPersonalization.age }}</h3>
          <h3>{{ currentPersonalization.students }}</h3>
          <br />
          <p
            v-for="(info, infoIndex) in currentPersonalization.info"
            :key="infoIndex"
          >
            {{ info }}
          </p>
        </div>
      </div>

      <div class="right-img">
        <img :src="currentImageSrc" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPersonalizationIndex: 0,

      personalizationData: [
        {
          age: "兴趣启蒙 K0",
          students: "适合学龄前（3-4岁）学员",
          info: [
            "专为零基础幼儿设置的幼少衔接课程。以活泼欢快的课堂，",
            "充满童趣的音乐，生动形象的模仿，带孩子初步认识和运用",
            "身体，在玩耍中培养舞蹈兴趣。",
          ],
        },
        {
          age: "系统进阶（K1-K11）",
          students: "适合4-12岁学员",
          info: [
            "覆盖艺途国际自主研发核心教材体系，初、中、高阶课程科",
            "学划分，囊括基本功、技术技巧、情绪训练、作品表现等教",
            "学内容。通过舞蹈，让孩子收获优美形体、自信大方！",
          ],
        },
        {
          age: "专业深造（K12）",
          students: "适合14岁以上学员",
          info: [
            "与专注艺术高考培训二十余年的一线教师接轨，重视舞蹈作",
            "品综合能力训练，衔接艺考课程。",
          ],
        },
      ],
      imageArr: [
        require("../../../assets/img/personalization1.png"),
        require("../../../assets/img/personalization2.png"),
        require("../../../assets/img/personalization3.png"),
      ],
    };
  },
  computed: {
    currentPersonalization() {
      return this.personalizationData[this.currentPersonalizationIndex];
    },
    currentImageSrc() {
      return this.imageArr[this.currentPersonalizationIndex];
    },
  },
  methods: {
    showPersonalization(index) {
      this.currentPersonalizationIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.personalization {
  background: #f5f5f8;
  .title {
    text-align: center;
    margin: 0px auto;
    margin-top: 100px;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }
  .personalization {
    width: 1002px;
    height: auto;
    margin: 60px auto;
    display: flex;
    .left-content {
      width: 572px;
      .btns {
        width: 447px;
        display: flex;
        div {
          width: 155px;
          height: 49px;
          text-align: center;
          line-height: 49px;
          background: #e0e0e0;
          border-radius: 25px;
          margin-right: 10px;
          cursor: pointer;
        }
        .activeClass {
          color: white;
          background: #851d29;
        }
      }
      .btns .active {
        background-color: #851d29;
        color: white;
        transition: all 0.3s;
      }
      .top-txt {
        margin-top: 30px;
        transition: all 0.3s;
        h3 {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
        p {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
        }
      }
    }
    .right-img {
      width: 430px;
      height: 316px;
    }
  }
}
</style>