<template>
  <div class="ShiftType clearfix">
    <div class="title">
      <h3>班型设置</h3>
    </div>
    <div class="listBg">
      <div class="card1" v-lazy-background="brandImage(0)">
        <div class="titles">
          <h3>1v1课程</h3>
        </div>
        <div class="people-info">
          <p>·人数：1人</p>
          <p>·课时：90分钟</p>
        </div>
        <div class="btns">
          <span>立即咨询</span>
        </div>
      </div>
      <div class="card2" v-lazy-background="brandImage(1)">
        <div class="titles">
          <h3>精品小班课</h3>
        </div>
        <div class="people-info">
          <p>·人数：5-12人</p>
          <p>·课时：90分钟</p>
        </div>
        <div class="btns">
          <span>立即咨询</span>
        </div>
      </div>
      <div class="card3" v-lazy-background="brandImage(2)">
        <div class="titles">
          <h3>排练班课</h3>
        </div>
        <div class="people-info">
          <p>·人数：15-20人</p>
          <p>·课时：90分钟</p>
        </div>
        <div class="btns">
          <span>立即咨询</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carArr: [
        require("../../../assets/img/Card1.png"),
        require("../../../assets/img/Card2.png"),
        require("../../../assets/img/Card3.png"),
      ],
    };
  },
  computed: {
    brandImage() {
      return (index) => this.carArr[index];
    },
  },
};
</script>

<style lang="less" scoped>
.ShiftType {
  width: 100%;
  height: auto;
  .title {
    text-align: center;
    margin: 82px auto;
    h3 {
      font-size: 32px;
      font-weight: bold;
      color: #333333;
    }
  }
  .listBg {
    width: 1036px;
    height: 416px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
  }
  .card1,
  .card2,
  .card3 {
    width: 333px;
    height: 416px;

    .titles {
      width: 300px;
      height: 160px;
      text-align: center;
      margin: 10px auto;
      h3 {
        font-size: 28px;
        font-weight: bold;
        color: #ffffff;
        line-height: 160px;
      }
    }
    .people-info {
      width: 300px;
      height: 100px;
      text-align: center;
      margin: 25px auto;

      p {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        line-height: 40px;
      }
    }
    .btns {
      width: 183px;
      height: 52px;
      background: #dbbc7d;
      border-radius: 26px;
      margin: 0 auto;
      text-align: center;
      cursor: pointer;
      span {
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        line-height: 52px;
      }
    }
  }

  .card2 {
    .btns {
      background: #e57069;
    }
  }
  .card3 {
    .btns {
      background: #aec9ef;
    }
  }
}
</style>