<template>
  <div class="Teachers clearfix">
    <div class="title">师资力量</div>
    <div class="content">
      <div class="left-info clearfix">
        <div class="left-info clearfix">
          <div class="user">
            <div class="name">{{ currentUser.name }}</div>
            <div class="info">
              <p v-for="(info, infoIndex) in currentUser.info" :key="infoIndex">
                {{ info }}
              </p>
            </div>
          </div>
        </div>
        <div class="btns">
          <div
            class="letf-btn normalClass"
            @click="showUserInfo(-1, 0)"
            :class="{ active: activeButtonIndex === 0 }"
          >
            <img src="../../../assets/img/left-up-black.png" ref="left" />
          </div>
          <div
            class="right-btn normalClass"
            @click="showUserInfo(1, 1)"
            :class="{ active: activeButtonIndex === 1 }"
          >
            <img
              class="rotate"
              src="../../../assets/img/left-up-black.png"
              ref="right"
            />
          </div>
        </div>
      </div>
      <div class="right-info">
        <div class="ytTitle">YITU</div>
        <img class="img" :src="currentImageSrc" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeButtonIndex: -1, // index of the currently active button
      currentUserIndex: 0, // index of the currently displayed user
      userInfo: [
        {
          name: "张茜雯",
          info: [
            "舞蹈学科带头人。",
            "北京电视台卡酷少儿频道《维他命家族》舞蹈总监。101火",
            "箭少女团水立方发布会开场舞编导，合作艺人李孝利、蔡",
            "妍，米娜等。全国特长生舞蹈大赛，海外桃李杯大赛，绽美",
            "舞蹈大赛等评委老师。澳洲cats舞蹈考级指导老师。曾担任",
            "灰姑娘舞蹈艺术中心校区舞蹈主管，舞悦天舞蹈学苑舞蹈总",
            "监。北京市昌平区文学艺术界联合会理事。",
          ],
        },
        {
          name: "刘翠玉",
          info: [
            "著名民族民间舞教授。",
            "舞蹈老前辈，原解放军艺术学院民间舞创始人兼教研组组",
            "长，著名民族民间舞教授。",
          ],
        },
        {
          name: "宋琛",
          info: [
            "原中央芭蕾舞团一级演员。",
            "原中央芭蕾舞团国家一级演员，舞剧（红色娘子军）连长，",
            "舞蹈（草原儿女）妈妈的扮演者。",
          ],
        },
        {
          name: "庄西安",
          info: [
            "原总政文工团领舞 。",
            "原总政文工团领舞，学员队政委，原文化部下属博爱国际孤",
            "儿艺术学校校长。",
          ],
        },
        {
          name: "孙鹏",
          info: [
            "青年舞蹈家。",
            "就职于中国东方演艺集团，",
            "国家一级舞蹈演员。",
          ],
        },
        {
          name: "曾明",
          info: [
            "国家一级演员，青年舞蹈家，中国舞蹈家协会会员。",
            "毕业于北京舞蹈学院古典舞系，现任中国东方演艺集团中国",
            "歌舞团首席舞蹈演员。",
            "作品包括：古典舞《荷塘月色》、《酒狂》、《竹石》等。",
          ],
        },
        {
          name: "李蓝紫",
          info: [
            "中央民歌舞团舞蹈演员 。",
            "中央民族歌舞团舞蹈演员，著名舞蹈家陈爱莲老师的爱徒，",
            "爱莲版舞剧（红楼梦）王熙凤的扮演者。",
          ],
        },
        {
          name: "张茜雯",
          info: [
            "舞蹈总监、少儿舞蹈考级指导教师 。",
            "北京电视台卡酷少儿频道《维他命家族》舞蹈总监。全国特",
            "长生舞蹈大赛、海外桃李杯大赛、绽美舞蹈大赛等评委老",
            "师。澳洲cats舞蹈考级指导老师，北京市昌平区文学艺术界",
            "联合会理事。",
          ],
        },
        {
          name: "汤旖婷",
          info: [
            "北京舞蹈学院硕士研究生，曾于2014年获得湖南省职业技",
            "能大赛一等奖、全国职业技能大赛三等奖，曾参与2015年",
            "全国城市春晚录制、2019年世界军人运动会开幕式等演出",
            "活动。拥有多年舞蹈教学实践经验，授课风格幽默风趣，深",
            "受学生喜爱。",
         
          ],
        },
        {
          name: "龚雨靖",
          info: [
            "北京舞蹈学院中国古典舞教育专业，",
            "舞龄十三年，身韵师从王伟等教授，",
            "连续三年获得市级、省级舞蹈大赛一等奖。",
          ],
        },
      ],
      imageSrcList: [
        require("../../../assets/img/headSculpture/headSculpture1.png"),
        require("../../../assets/img/headSculpture/headSculpture2.png"),
        require("../../../assets/img/headSculpture/headSculpture3.png"),
        require("../../../assets/img/headSculpture/headSculpture4.png"),
        require("../../../assets/img/headSculpture/headSculpture5.png"),
        require("../../../assets/img/headSculpture/headSculpture6.png"),
        require("../../../assets/img/headSculpture/headSculpture7.png"),
        require("../../../assets/img/headSculpture/headSculpture8.png"),
        require("../../../assets/img/headSculpture/headSculpture9.png"),
        require("../../../assets/img/headSculpture/headSculpture10.png"),
      
      ],
    };
  },
  computed: {
    currentUser() {
      return this.userInfo[this.currentUserIndex];
    },
    currentImageSrc() {
      return this.imageSrcList[this.currentUserIndex];
    },
  },
  methods: {
    showUserInfo(increment, buttonIndex) {
      this.currentUserIndex += increment;
      this.currentUserIndex = Math.max(
        0,
        Math.min(this.currentUserIndex, this.userInfo.length - 1)
      );

      this.activeButtonIndex = buttonIndex;
      if (increment === -1) {
        this.$refs.left.src = require("../../../assets/img/left-up-white.png");
        this.$refs.right.src = require("../../../assets/img/left-up-black.png");
        this.$refs.left.className = "rotate";
        this.$refs.right.className = "rotate";
      } else {
        this.$refs.left.src = require("../../../assets/img/left-up-black.png");
        this.$refs.left.className = "";
        this.$refs.right.src = require("../../../assets/img/left-up-white.png");
        this.$refs.right.className = "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Teachers {
  padding-bottom: 200px;
  .title {
    text-align: center;
    margin: 50px auto;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
    margin-bottom: 50px;
  }
  .content {
    width: 1000px;
    height: 400px;
    margin: 0 auto;

    display: flex;
    .left-info {
      width: 471px;
      height: 216px;
      margin-top: 50px;
      .name {
        width: 87px;
        height: 48px;
        font-size: 28px;
        font-weight: 500;
        color: #333333;
      }
      .info {
        width: 471px;
        height: 216px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
      .btns {
        width: 300px;
        margin: 50px auto;
        height: 60px;
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        img {
          width: 22px;
          height: 36px;
        }
      }

      .normalClass {
        display: flex;
        width: 60px;
        height: 60px;
        background: #d8d8d8;
        border-radius: 39px;
        align-items: center;
        justify-content: center;
      }
      .rotate {
        transform: rotate(180deg);
      }
      .active {
        background-color: #851d29; /* Change this to the desired background color */
      }
    }
    .right-info {
      width: 529px;
      height: 100%;

      .ytTitle {
        width: 497px;
        height: auto;
        font-size: 150px;
        font-weight: 800;
        color: #dedede;
        margin-top: -65px;
      }
      .img {
        position: relative;
        top: -95px;
        left: 50px;
      }
    }
  }
}
</style>