<template>
  <div class="CompetencyModel clearfix">
    <div class="title">素质模型</div>
    <div class="box">
      <div class="left-ppt">
        <div class="top-title">
          <h3>智慧美育目标</h3>
        </div>
        <div class="line-txt">
          <div>知识</div>
          <div style="left: 10px">理想</div>
          <div style="left: 25px">情操</div>
        </div>
        <div class="line-txt">
          <div>品格</div>
          <div style="left: 10px">素养</div>
          <div style="left: 25px; opacity: 0">素养</div>
        </div>
      </div>
      <div class="right-txt">
        <p>
          依托于艺途国际办学十余年反复验证的教育方针，结合众多专家、学者的教学经验，艺途打造出一套兼具科学性、稳定性和实用性的教学体系。在艺术教学中，围绕“智慧美育”的目标，对传统教学法进行情感性和趣味性的突破创新，让孩子在艺术体验中逐步形成健康人格，最终实现德、智、体、美、劳全面发展。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.CompetencyModel {
    padding-bottom: 100px;
  .title {
    text-align: center;
    margin: 70px auto;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }
  .box {
    width: 1000px;
    height: auto;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    .left-ppt {
      width: 486px;
      height: 296px;
      background: url(../../../assets/img/modle.png) no-repeat center;
      background-size: cover;
      .top-title {
        width: 222px;
        margin-left: 43px;
        height: 81px;
        text-align: center;
        line-height: 81px;

        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
      }
      .line-txt {
        width: 425px;
        height: 60px;
        margin: 22px auto;
        display: flex;

        font-size: 18px;
        font-weight: bold;
        color: #333333;
        align-items: center;
        justify-content: space-around;
        padding-bottom: 3px;
        div {
          position: relative;
        }
      }
    }
    .right-txt {
      width: 489px;
      height: 192px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 32px;
      text-align: justify;
    }
  }
}
</style>