<template>
  <div class="GrowthSystem clearfix">
    <div class="title">
      <h3>成长体系</h3>
    </div>
    <div class="raius">
      <div class="content">
        <div class="left">
          <div class="top-txt">认识美</div>
          <div class="left-txt">创造美</div>
          <div class="center-txt">舞蹈作为美育 的重要组成部分</div>
          <div class="right-txt">感受美</div>
          <div class="bottom-txt">表现美</div>
        </div>
        <div class="centent">
          <div class="titles">
            <p>艺途舞蹈优势</p>
            <div class="box-three">
              <h3 style="position: relative; top: 12px">舞蹈师资</h3>
              <h3>舞蹈课程</h3>
              <h3 style="position: relative; top: -12px">实践活动</h3>
            </div>
            <div class="lr">艺途舞蹈课程设置</div>
          </div>
        </div>
        <div class="right">
          <div class="txt" >
            <p>儿童素质教育</p>
            <p>舞蹈（男、女）班</p>
          </div>
          <div class="txt" style="top:-22px">
            <p>儿童中国舞</p>
          </div>
          <div class="txt"  style="top:-20px">
            <p>儿童街舞</p>
          </div>
          <div class="txt"  style="top:-18px">
            <p>创造性舞蹈</p>
          </div>
          <div class="txt"  style="top:-12px">
            <p>国标舞</p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-txt">
      <p>
        舞蹈作为美育的重要组成部分，具有认识美，感受美，表现美，创造美的功能。艺途国际舞蹈艺术教学立足自身职能和定位，充分发挥舞蹈师资、课程、实践活动等优势，不断拓宽课程领域，贯彻融合理念，开展学科融合与创新。依据儿童身心特质，招生年龄3.5-15岁，设置启蒙，初级，中级，高级。让舞蹈教育带给孩子们健康美丽的人生，在促进“人的全面发展”中发挥应有的作用。舞蹈课程设置：儿童素质教育舞蹈（男、女）班、儿童中国舞、儿童街舞、创造性舞蹈、国标舞。
      </p>
      <br />
      <p style="font-size: 14px; text-align: justify">
        Dance,as an important component of aesthetic education,has the function
        of recognizing beauty,experiencing beauty,expressing beauty,and creating
        beauty.Yitu International Dance Art Teaching is based on its own
        functions and positioning,fully leveraging the advantages of dance
        teachers,courses,practical activities,etc.,continuously expanding the
        scope of courses,implementing the concept of integration,and carrying
        out disciplinary integration and innovation.Based on the physical and
        mental characteristics of children,the enrollment age is between 3.5 and
        15 years old,with settings of Enlightenment,Elementary,Intermediate,and
        Advanced.Let Dance education bring children a healthy and beautiful life
        and play its due role in promoting"all-round development of
        people".Dance curriculum:children's quality education dance(male and
        female)class,children's Dance in China,children's street dance,creative
        dance,national standard dance.
      </p>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style lang="less" scoped>
.GrowthSystem {
  width: 100%;
  background: url(../../../assets/img/growth-systemDanceBg.png) no-repeat center;
  background-size: cover;
  height: 936px;
  .title {
    text-align: center;
    margin: 0px auto;
    margin-top: 70px;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }
  .raius {
    width: 1004px;
    height: 408px;
    background: url(../../../assets/img/growth-systemRadius.png) no-repeat
      center;
    background-size: cover;
    margin: 50px auto;
    .content {
      width: 100%;
      display: flex;
      align-items: center;
      height: 466px;
      .left {
        width: 38%;
        height: 70%;
        position: relative;
        .top-txt,
        .bottom-txt {
          left: 50%;
          transform: translate(-50%);
          color: white;
          position: absolute;
          font-size: 18px;
        }
        .bottom-txt {
          bottom: 45px;
        }
        .left-txt,
        .right-txt {
          position: absolute;
          font-size: 18px;
          top: 40%;
        }
        .left-txt {
          left: 30px;
        }
        .right-txt {
          right: 25px;
        }
        .center-txt {
          position: absolute;
          left: 50%;
          top: 36%;
          transform: translate(-40%);
          width: 126px;
          height: 64px;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
      }
      .centent {
        margin-left: 30px;
        width: 270px;
        height: 95%;
        position: relative;
        .titles {
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
        .box-three {
          margin: 32px auto;
          width: 300px;
          height: 270px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          h3 {
            font-size: 24px;
            font-weight: bold;
            color: #ffffff;
          }
        }
        .lr {
          height: 200px;
          position: absolute;
          right: -32px;
          writing-mode: vertical-lr;
          top: 100px;
        }
      }
      .right {
        width: 200px;
        height: 95%;
        margin-left: 122px;
        display: flex;
        flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
        .txt {
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          position: relative;
          top: -30px;
        }
      }
    }
  }
  .info-txt {
    margin: 0 auto;
    width: 1000px;
    height: 160px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 23px;
  }
}
</style>