<template>
  <div class="DanceLesson clearfix">
    <div class="title">
      <h3>艺途国际舞蹈学科 完整课程体系</h3>
    </div>
    <div class="listImg">
        <div class="box" v-for="(item, i) in listData" :key="i">
          <img :src="item.url" alt="">
          <p>{{item.title}}</p>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        listData:[
          {
            url:require("../../../assets/img/DanceLesson1.png"),
            title:"拉丁舞"
          },
          {
            url:require("../../../assets/img/DanceLesson2.png"),
            title:"爵士舞"
          },
          {
            url:require("../../../assets/img/DanceLesson3.png"),
            title:"中国舞"
          },
          {
            url:require("../../../assets/img/DanceLesson4.png"),
            title:"少儿拉丁舞"
          },
          {
            url:require("../../../assets/img/DanceLesson5.png"),
            title:"成人肚皮舞"
          },
        ]
    };
  },
 
};
</script>

<style lang="less" scoped>
.DanceLesson {
  .title {
    text-align: center;
    margin: 90px auto;
    margin-bottom: 50px;
    h3 {
      font-size: 32px;
      font-weight: bold;
      color: #333333;
    }
  }
  .listImg{
    width: 1000px;
    height: auto;
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .box {
      text-align: center;
      padding-bottom: 40px;
      p{
        padding-top: 20px;
      }
    }
  }
}
</style>