<template>
  <div class="TeachingContent clearfix">
    <div class="title">
      <h3>授课内容</h3>
    </div>
    <div class="TeachingContentImg">
      <div class="list" v-for="(item, i) in TopData" :key="i">
        <img :src="item.imgUrl" :key="i" v-lazy-img="item.imgUrl"/>
        <p>{{ item.info }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      TopData: [
        {
          imgUrl: require("../../../assets/img/teaching1.png"),
          info: "古典舞剧目",
        },
        {
          imgUrl: require("../../../assets/img/teaching2.png"),
          info: "少儿舞蹈理论",
        },
        {
          imgUrl: require("../../../assets/img/teaching3.png"),
          info: "个人创编",
        },
        {
          imgUrl: require("../../../assets/img/teaching4.png"),
          info: "复合型舞蹈技巧",
        },
        {
          imgUrl: require("../../../assets/img/teaching5.png"),
          info: "民族民间元素组合",
        },
        {
          imgUrl: require("../../../assets/img/teaching6.png"),
          info: "古典舞基训综合组合",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.TeachingContent {
  padding-bottom: 60px;
  .title {
    margin: 76px auto;
    text-align: center;
    h3 {
      font-size: 32px;
      font-weight: bold;
      color: #333333;
    }
  }
  .TeachingContentImg {
    width: 1055px;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .list {
      text-align: center;
      padding-bottom: 20px;
      p {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
    }
  }
}
</style>