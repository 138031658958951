var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ShiftType clearfix"},[_vm._m(0),_c('div',{staticClass:"listBg"},[_c('div',{directives:[{name:"lazy-background",rawName:"v-lazy-background",value:(_vm.brandImage(0)),expression:"brandImage(0)"}],staticClass:"card1"},[_vm._m(1),_vm._m(2),_vm._m(3)]),_c('div',{directives:[{name:"lazy-background",rawName:"v-lazy-background",value:(_vm.brandImage(1)),expression:"brandImage(1)"}],staticClass:"card2"},[_vm._m(4),_vm._m(5),_vm._m(6)]),_c('div',{directives:[{name:"lazy-background",rawName:"v-lazy-background",value:(_vm.brandImage(2)),expression:"brandImage(2)"}],staticClass:"card3"},[_vm._m(7),_vm._m(8),_vm._m(9)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("班型设置")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titles"},[_c('h3',[_vm._v("1v1课程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"people-info"},[_c('p',[_vm._v("·人数：1人")]),_c('p',[_vm._v("·课时：90分钟")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btns"},[_c('span',[_vm._v("立即咨询")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titles"},[_c('h3',[_vm._v("精品小班课")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"people-info"},[_c('p',[_vm._v("·人数：5-12人")]),_c('p',[_vm._v("·课时：90分钟")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btns"},[_c('span',[_vm._v("立即咨询")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titles"},[_c('h3',[_vm._v("排练班课")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"people-info"},[_c('p',[_vm._v("·人数：15-20人")]),_c('p',[_vm._v("·课时：90分钟")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btns"},[_c('span',[_vm._v("立即咨询")])])
}]

export { render, staticRenderFns }