<template>
  <div>
    <title-yt />
    <nav-top />
    <banner/>
    <teacher/>
    <development/>
    <dance-lesson/>
    <personalization/>
    <shift-type/>
    <teaching-content/>
    <growth-system/>
    <competency-model/>
    <environment/>
    <brand/>

    <copyright/> 
  </div>
</template>

<script>
import Copyright from '../../pubilic/copyright.vue';
import NavTop from "../../pubilic/NavTop.vue";
import TitleYt from "../../pubilic/TitleYt.vue";
import Banner from '../Dance/Banner.vue';
import CompetencyModel from '../Painting/CompetencyModel.vue';
import Brand from './Brand.vue';
import DanceLesson from './DanceLesson.vue';
import Development from './development.vue';
import Environment from './environment.vue';
import GrowthSystem from './GrowthSystem.vue';
import Personalization from './personalization.vue';
import ShiftType from './ShiftType.vue';
import Teacher from './Teacher.vue';
import TeachingContent from './TeachingContent.vue';


export default {
  components: { TitleYt, NavTop, Banner,DanceLesson, ShiftType, TeachingContent, Teacher, Brand,Copyright, Development, Personalization, GrowthSystem, CompetencyModel, Environment },
};
</script>
<style></style>;
