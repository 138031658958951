<template>
  <div class="development clearfix">
    <div class="title">
      <h3>一站式艺术教学阶梯成长体系</h3>
    </div>
    <div class="box">
      <div class="left-tiitle">
        <div class="content">
          <span style="height: 70px">年龄</span>
          <span style="height: 70px">级别</span>
          <span style="height: 150px; line-height: 150px">教学内容</span>
          <span style="height: 150px; line-height: 150px"> 教学特色</span>
        </div>
      </div>
      <div class="right-txt">
        <div class="top-tittle">
          <p style="margin-left: -7px">启蒙</p>
          <p>启智</p>
          <p>进阶</p>
          <p>拓展</p>
          <p style="margin-left: 17px">专业</p>
        </div>
        <div class="age">
          <p>3-4岁</p>
          <p>5-6岁</p>
          <p>7-9岁</p>
          <p>10-13岁</p>
          <p>14岁+</p>
        </div>
        <div class="level">
          <p>K0</p>
          <p>K1-3</p>
          <p>K4-6</p>
          <p>K7-11</p>
          <p>K12</p>
        </div>
        <div class="TeachingContent">
          <div>
            <p>认识身体</p>
            <p>兴趣培养</p>
          </div>
          <div>
            <p>基本体态</p>
            <p>节奏感协调</p>
            <p>幼儿技巧训练</p>
          </div>
          <div>
            <p>中级技巧训练</p>
            <p>舞蹈情绪训练</p>
            <p>素质训练</p>
          </div>
          <div>
            <p>高级技巧训练</p>
            <p>舞蹈作品情绪训练</p>
            <p>表现力训练</p>
            <p>民族舞训练</p>
            <p>古典舞训练</p>
          </div>
          <div>
            <p>综合能力</p>
            <p>训练</p>
          </div>
        </div>
        <div class="characteristic">
          <div>
            <p>了解节奏</p>
            <p>培养习惯</p>
            <p>初步认知</p>
          </div>
          <div>
            <p>基本训练法</p>
            <p>协调训练</p>
            <p>基本训练法</p>
            <p>协调训练</p>
          </div>
          <div>
            <p>基本功训练</p>
            <p>技术技巧训练</p>
            <p>原创组合训练</p>
          </div>
          <div>
            <p>成品剧目训练</p>
            <p>舞蹈神韵训练</p>
          </div>
          <div>
            <p>完成完整舞蹈作品</p>
            <p>思维发散编导</p>
            <p>作品专业进阶</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style lang="less" scoped>
.development {
  width: 100%;
  height: 936px;
  background: url(../../../assets/img/developmentBg.png) no-repeat center;
  background-size: cover;
  .title {
    text-align: center;
    margin: 0px auto;
    margin-top: 100px;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }
  .box {
    width: 1000px;
    margin: 0px auto;
    margin-top: 150px;

    height: 511px;
    display: flex;
    .left-tiitle {
      width: 90px;
      height: 100%;

      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      .content {
        height: 436px;
        width: 100%;

        display: flex;
        flex-direction: column;
        span {
          font-size: 18px;
          font-weight: 500;
          color: #851d29;
        }
      }
    }

    .right-txt {
      width: 910px;
      height: 511px;
      background: url(../../../assets/img/czkcBg.png) no-repeat center;
      background-size: cover;
      .top-tittle {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        p {
          width: 166px;
          text-align: right;
          font-size: 26px;
          font-weight: 500;
          color: #ffffff;
          margin-left: 10px;
        }
      }
      .age,
      .level {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        p {
          width: 25%;
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .TeachingContent,
      .characteristic {
        width: 100%;
        height: 164px;
        display: flex;
        align-items: center;
        padding-bottom: 8px;

        div {
          width: 25%;
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .characteristic {
        height: 135px;
      }
    }
  }
}
</style>