<template>
    <div class="banner">
        <img src="../../../assets/img/Dancebanner.png" alt="">
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>
    .banner{
        width: 100%;
        height: 665px;
        img{
            width: 100%;
            height: 665px;
           
        }
    }
</style>